.footer {
    background-color: lightgray;
    display: flex;
    flex-direction: column;
}

.supDiv{
    display: flex;
    justify-content: center;
}
.divHover {
    align-items: center;
    display: flex;
}

.img {
    width: 100px;
}

.linkDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    }

#bookeaseMail {
    font-size: 14px;
    margin: 0;
}

#bookeaseImg {
    width: 38px;
    margin: 6px;
}

#bookeaseTitle {
    height: 28px;
    margin: 6px;
}

#bookeaseBottom {
    align-self: center;
    font-size: 12px;
}

.appLinks{
    display: flex;
   justify-content: center;
   align-items: center;
   gap: 10px;
}

@media screen and (min-width: 600px) {

    .linkDiv {
        align-items: start;
    }
    .footer {
        height: 220px;
        padding: 4vw;
        margin-top: 100px;
    }
    .supDiv{
        display: flex;
        justify-content: left;
    }
    .divHover {
        width: fit-content;
        align-items: center;
        display: flex;
    }
    
    .img {
        width: 115px;
    }

    #bookeaseMail {
        font-size: 15px;
    }
    
    #bookeaseImg {
        width: 46px;
        margin: 7px;
    }
    
    #bookeaseTitle {
        height: 30px;
        margin: 7px;
    }
    
    #bookeaseBottom {
        align-self: center;
        font-size: 13px;
    }
  }


@media screen and (min-width: 770px) {
    .footer {
        height: 220px;
        padding: 3vw;
        margin-top: 100px;
    }
    
    .divHover {
        align-items: center;
        display: flex;
    }
    
    .img {
        width: 135px;
    }

    #bookeaseMail {
        font-size: 16px;
    }
    
    #bookeaseImg {
        width: 50px;
        margin: 8px;
    }
    
    #bookeaseTitle {
        height: 32px;
        margin: 8px;
    }
    
    #bookeaseBottom {
        align-self: center;
        font-size: 14px;
    }
  }

@media screen and (min-width: 900px) {
    .footer {
        height: 250px;
        padding: 3vw;
        margin-top: 100px;
    }
    
    .divHover {
        align-items: center;
        display: flex;
    }
    
    .img {
        width: 170px;
    }

    #bookeaseMail {
        font-size: 18px;
    }
    
    #bookeaseImg {
        width: 56px;
        margin: 9px;
    }
    
    #bookeaseTitle {
        height: 36px;
        margin: 9px;
    }
    
    #bookeaseBottom {
        align-self: center;
        font-size: 16px;
    }
  }

@media screen and (min-width: 1130px) {
    .footer {
        height: 270px;
        padding: 2vw;
        margin-top: 80px;
    }
    
    .divHover {
        align-items: center;
        display: flex;
    }
    
    .img {
        width: 200px;
    }

    #bookeaseMail {
        font-size: 20px;
    }
    
    #bookeaseImg {
        width: 60px;
        margin: 10px;
    }
    
    #bookeaseTitle {
        height: 40px;
        margin: 10px;
    }
    
    #bookeaseBottom {
        align-self: center;
        font-size: 18px;
    }
  }