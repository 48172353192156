.container {
    display: flex;
    flex-direction: column;
}

.titleDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70%;
}


.screenshotDiv {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
}



.screenshotDiv img {
    min-width: 250px;
    width: 30%;
}

.screenshotDivIn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.screenshotDivInRev {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.screenshotDivIn div h2, .screenshotDivInRev div h2{
    text-align: center;
    font-size: 23px;
}

.screenshotDivIn div p, .screenshotDivInRev div p{
    text-align: center;
    font-size: 15px;
}

#title {
    font-size: 27px;
}

#subtitle {
    font-size: 15px;
    font-weight: 500;
}

#image {
    min-width: 250;
    width: 20%;
}

.bookAmount {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.bookAmount h2 {
    font-size: 20px;
    font-weight: 200;
    margin: 0;
    padding: 0;
    margin-left: 10px;
}

.countUp{
    font-size: 20px;
    font-weight: bold;
}




@media screen and (min-width: 600px) {
    .screenshotDivIn div h2{
        text-align: center;
        font-size: 30px;
    }

    .screenshotDivIn {
        flex-direction: row;
        justify-content: space-evenly;
    }
    .screenshotDivInRev {
        flex-direction: row;
        justify-content: space-evenly;
    }

    .countUp {
        font-size: 30px;
    }
    
    .screenshotDivIn div p, .screenshotDivInRev div p{
        text-align: center;
        font-size: 19px;
    }
    
    #title {
        font-size: 40px;
    }
    
    #subtitle {
        font-size: 17px;
        font-weight: 500;
    }
  }


@media screen and (min-width: 770px) {
    .screenshotDivIn div h2, .screenshotDivInRev div h2{
        text-align: center;
        font-size: 35px;
    }
    
    .screenshotDivIn div p, .screenshotDivInRev div p{
        text-align: center;
        font-size: 21px;
    }
    
    #title {
        font-size: 60px;
    }
    
    #subtitle {
        font-size: 19px;
        font-weight: 500;
    }
  }

  @media screen and (min-width: 900px) {
    .screenshotDivIn div h2, .screenshotDivInRev div h2{
        text-align: center;
        font-size: 37px;
    }
    
    .screenshotDivIn div p, .screenshotDivInRev div p{
        text-align: center;
        font-size: 22px;
    }
    
    #title {
        font-size: 70px;
    }
    
    #subtitle {
        font-size: 20px;
        font-weight: 500;
    }
  }

  @media screen and (min-width: 1130px) {
    .screenshotDivIn div h2, .screenshotDivInRev div h2{
        text-align: center;
        font-size: 40px;
    }
    
    .screenshotDivIn div p, .screenshotDivInRev div p{
        text-align: center;
        font-size: 23px;
    }
    
    #title {
        font-size: 80px;
    }
    
    #subtitle {
        font-size: 21px;
        font-weight: 500;
    }
  }