h1 {
    text-align: center;
    font-weight: bold;
    font-size: 17px;
}

.first {
    -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}

.first:hover {
    box-shadow: 0 0 40px 40px #5a97ff inset;
}

.btnlogin {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 0.6em;
    color: #5a97ff;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 400;
    line-height: 1;
    margin: 10px;
    padding: 0.8em 1.6em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    border-width: 2px;
    border-style: solid;
    border-color: #5a97ff;
    width: 150px;
}

.btnlogin:hover,
.btnlogin:focus {
    color: #fff;
    outline: 0;
}

.custom-select {
    width: 200px;
  }

.input {
    width: 180px;
    padding: 10px;
    border-radius: 10px;
    border-width: 1px;
}

#DoYouNeedAnAccount {
    font-size: 10px;
}

  @media screen and (min-width: 600px) {
    h1 {
        font-size: 20px;
    }
    
    .btnlogin {
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 0.6em;
        color: #5a97ff;
        cursor: pointer;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 400;
        line-height: 1;
        margin: 10px;
        padding: 0.8em 1.6em;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        border-width: 2px;
        border-style: solid;
        border-color: #5a97ff;
        width: 160px;
    }
    
    .btnlogin:hover,
    .btnlogin:focus {
        color: #fff;
        outline: 0;
    }
    
    .custom-select {
        width: 200px;
      }
    
    .input {
        width: 215px;
        padding: 10px;
        border-radius: 10px;
        border-width: 1px;
    }
    
    #DoYouNeedAnAccount {
        font-size: 11px;
    }
  }


@media screen and (min-width: 770px) {
    h1 {
        font-size: 23px;
    }
    
    .btnlogin {
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 0.6em;
        color: #5a97ff;
        cursor: pointer;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 400;
        line-height: 1;
        margin: 10px;
        padding: 0.8em 1.6em;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        border-width: 2px;
        border-style: solid;
        border-color: #5a97ff;
        width: 175px;
    }
    
    .btnlogin:hover,
    .btnlogin:focus {
        color: #fff;
        outline: 0;
    }
    
    .custom-select {
        width: 200px;
      }
    
    .input {
        width: 240px;
        padding: 10px;
        border-radius: 10px;
        border-width: 1px;
    }
    
    #DoYouNeedAnAccount {
        font-size: 12px;
    }
  }

  @media screen and (min-width: 900px) {
    h1 {
        font-size: 26px;
    }
    
    .btnlogin {
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 0.6em;
        color: #5a97ff;
        cursor: pointer;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 400;
        line-height: 1;
        margin: 10px;
        padding: 0.8em 1.6em;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        border-width: 2px;
        border-style: solid;
        border-color: #5a97ff;
        width: 185px;
    }
    
    .btnlogin:hover,
    .btnlogin:focus {
        color: #fff;
        outline: 0;
    }
    
    .custom-select {
        width: 200px;
      }
    
    .input {
        width: 270px;
        padding: 10px;
        border-radius: 10px;
        border-width: 1px;
    }
    
    #DoYouNeedAnAccount {
        font-size: 13px;
    }
  }

  @media screen and (min-width: 1130px) {
    h1 {
        font-size: 30px;
    }
    
    .btnlogin {
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 0.6em;
        color: #5a97ff;
        cursor: pointer;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 400;
        line-height: 1;
        margin: 10px;
        padding: 0.8em 1.6em;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        border-width: 2px;
        border-style: solid;
        border-color: #5a97ff;
        width: 200px;
    }
    
    .btnlogin:hover,
    .btnlogin:focus {
        color: #fff;
        outline: 0;
    }
    
    .custom-select {
        width: 200px;
      }
    
    .input {
        width: 300px;
        padding: 10px;
        border-radius: 10px;
        border-width: 1px;
    }
    
    #DoYouNeedAnAccount {
        font-size: 15px;
    }
  }