
header {
    display: flex;
    justify-content: space-between;
    background-color: #eceff1;
    /* background: linear-gradient(to left,#5A8CFF, #003389); */
    /* background-color: #5a97ff; */
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ccd0d2;
}

.divHover:hover{
    cursor: pointer;
}

.authButtons {
    display: flex;
}

.fourth {
    border: none;
    color: #fff;
    background-image: -webkit-linear-gradient(45deg, #000000 50%, transparent 50%);
    background-image: linear-gradient(45deg, #426fbc 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.fourth:hover {
    background-position: 0;
}

.btn {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: black;
    border-radius: 0.6em;
    color: #fff;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 10px;
    padding: 0.6em 1.4em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.btn:hover,
.btn:focus {
    color: #fff;
    outline: 0;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    text-align: "center";
    font-weight: "bold";
    font-size: 2em;
}

.loggedInDiv {
    display: flex;
    background-color: transparent;
    border-style: none;
    align-items: center;
}

.loggedInDiv p {
    font-weight: 600;
    color:#20375e
}

.loggedInDiv:hover p,
.loggedInDiv:hover svg {
    color: white;
}